const orderModal = () => {
    const modal = document.querySelector('#modal')
    if (!modal) {
        return
    }

    appendUTM(modal)

    const openPlatform = document.querySelector('#open-modal-platform')
    const openFranchize = document.querySelector('#open-modal-franchize')

    const buttonsBackground = document.querySelector('.modal .switch-theme .background')

    const setButtonsBackgroundPosition = (element) => {
        // set left position of buttonsBackground
        const left = element.offsetLeft
        buttonsBackground.style.left = `${left}px`
    }

    // all links with href="#open-platform"
    const openPlatformLinks = document.querySelectorAll('a[href="#open-platform"]')
    const openFranchizeLinks = document.querySelectorAll('a[href="#open-franchize"]')

    const closeModal = modal.querySelector('.modal__close')
    const shadowModal = modal.querySelector('.modal__shadow')
    const switchToPlatform = document.querySelector('#modal-platform-switch')
    const switchToFranchize = document.querySelector('#modal-franchize-switch')
    const submit = modal.querySelector("input[type='submit']")

    if (switchToFranchize.classList.contains('switch-theme__btn--active')) {
        const left = switchToFranchize.offsetLeft
        buttonsBackground.style.left = `${left}px`
    }

    const mobileWidth = 1301

    const handleModal = ({
        darkMode = false,
        activeSwitch,
        inactiveSwitch,
        primaryButton = false,
    }) => {
        modal.classList.add('modal--opened')
        document.body.classList.add('body-locked')

        modal.classList.toggle('modal--dark', darkMode)

        activeSwitch?.classList.add('switch-theme__btn--active')
        inactiveSwitch?.classList.remove('switch-theme__btn--active')

        submit?.classList.toggle('button__primary', primaryButton)

        // set left position of buttonsBackground
        const left = activeSwitch.offsetLeft
        buttonsBackground.style.left = `${left}px`
    }

    // open platform modal
    openPlatform?.addEventListener('click', () => {
        handleModal({
            darkMode: false,
            activeSwitch: switchToPlatform,
            inactiveSwitch: switchToFranchize,
        })
        updateType('platform')
    })
    openPlatformLinks.forEach((link) => {
        link.addEventListener('click', () => {
            // if is mobile
            if (window.innerWidth < mobileWidth) {
                // scroll to #order
                const order = document.querySelector('#order')
                order.scrollIntoView({ behavior: 'smooth' })
            } else {
                handleModal({
                    darkMode: false,
                    activeSwitch: switchToPlatform,
                    inactiveSwitch: switchToFranchize,
                })
            }
        })
    })

    // open franchize modal
    openFranchize?.addEventListener('click', () => {
        handleModal({
            darkMode: true,
            activeSwitch: switchToFranchize,
            inactiveSwitch: switchToPlatform,
            primaryButton: true,
        })
        updateType('franchize')
    })
    openFranchizeLinks.forEach((link) => {
        link.addEventListener('click', () => {
            // if is mobile
            if (window.innerWidth < mobileWidth) {
                // scroll to #order
                const order = document.querySelector('#order')
                order.scrollIntoView({ behavior: 'smooth' })
            } else {
                handleModal({
                    darkMode: true,
                    activeSwitch: switchToFranchize,
                    inactiveSwitch: switchToPlatform,
                    primaryButton: true,
                })
            }
        })
    })

    closeModal?.addEventListener('click', () => {
        clearModalInfo()
    })

    shadowModal?.addEventListener('click', () => {
        clearModalInfo()
    })

    switchToPlatform?.addEventListener('click', (el) => {
        modal.classList.remove('modal--dark')
        switchToFranchize?.classList.remove('switch-theme__btn--active')
        switchToPlatform?.classList.add('switch-theme__btn--active')
        submit?.classList.remove('button__primary')
        setButtonsBackgroundPosition(el.target)
        updateType('platform')
    })

    switchToFranchize?.addEventListener('click', (el) => {
        modal.classList.add('modal--dark')
        switchToFranchize?.classList.add('switch-theme__btn--active')
        switchToPlatform?.classList.remove('switch-theme__btn--active')
        submit?.classList.add('button__primary')
        setButtonsBackgroundPosition(el.target)
        updateType('franchize')
    })

    const updateType = (type) => {
        const typeSelector = modal.querySelector('input[name="type"]')
        typeSelector.value = type
    }

    function clearModalInfo() {
        const outputMessage = document.querySelector('.form-row__output')
        const modalContent = document.querySelector('.order__modal')
        const form = modal.querySelector('.order-form')

        modal.classList.remove('modal--opened')
        modalContent?.classList.remove('order-modal__hidden')
        outputMessage?.classList.remove('success', 'error')
        outputMessage.innerHTML = ''
        document.body.classList.remove('body-locked')

        form.reset()
    }
}

const appendUTM = (modal) => {
    const url = new URL(window.location.href)
    const utmOldParamsLS = JSON.parse(localStorage.getItem('visitInfo'))

    const utmParams = [
        'utm_source',
        'utm_medium',
        'utm_campaign',
        'utm_term',
        'utm_content',
        'utm_page',
        'yclid',
    ]
    const utmOldParams = [
        'utm_old_source',
        'utm_old_medium',
        'utm_old_campaign',
        'utm_old_term',
        'utm_old_content',
        'utm_old_page',
    ]

    let inputsHTML = ''
    utmParams.forEach((param) => {
        const value = url.searchParams.get(param)
        if (value) {
            inputsHTML += `<input type="hidden" name="${param}" value="${encodeURIComponent(value)}" />`
        }
    })
    if (utmOldParamsLS) {
        utmOldParams.forEach((param) => {
            const value = utmOldParamsLS[param.replace('old_', '')]
            if (value) {
                inputsHTML += `<input type="hidden" name="${param}" value="${encodeURIComponent(value)}" />`
            }
        })
    }

    const currentUrl = window.location.origin + window.location.pathname
    inputsHTML += `<input type="hidden" name="form_page_url" value="${currentUrl}" />`

    const firstPageUrl = (localStorage.getItem('firstPageUrl')) ? localStorage.getItem('firstPageUrl') : currentUrl
    inputsHTML += `<input type="hidden" name="first_page_url" value="${firstPageUrl}" />`

    if (inputsHTML) {
        const modalForm = modal.querySelector('form')
        modalForm.insertAdjacentHTML('beforeend', inputsHTML)
    }
}

export default orderModal
